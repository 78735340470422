<template>
  <div>
    <ContentBase>
      <div class="row  justify-content-md-center">
        <div class="col-3">
          <form @submit.prevent="register">
            <div class="mb-3">
              <label for="username" class="form-label">用户名</label>
              <input v-model="username" type="text" class="form-control" id="username">
            </div>
            <div class="mb-3">
              <label for="password" class="form-label">密码</label>
              <input v-model="password" type="password" class="form-control" id="password">
            </div>
            <div class="mb-3">
              <label for="confirmedpassword" class="form-label">确认密码</label>
              <input v-model="confirmedpassword" type="password" class="form-control" id="confirmedpassword">
            </div>
            <div class="error-message">{{ error_message }}</div>
            <button type="submit" class="btn btn-primary">登录</button>
          </form>
        </div>
      </div>

    </ContentBase>
  </div>
</template>

<script>
import ContentBase from '@/components/ContentBase'
import { ref } from 'vue'
import { useStore } from 'vuex'
import router from '@/router/index'
import $ from 'jquery'
export default {
  name: 'RegisterView',
  components: {
    ContentBase,
  },
  setup() {
    const store = useStore();
    console.log(store, router)
    let username = ref('');
    let password = ref('');
    let confirmedpassword = ref('');
    let error_message = ref('');

    const register = () => {
      error_message.value = "";
      $.ajax({
        url: "https://app165.acapp.acwing.com.cn/myspace/user/",
        type: "post",
        data: {
          username: username.value,
          password: password.value,
          password_confirm: confirmedpassword.value,
        },
        success(resp) {
          if (resp.result === "success") {
            store.dispatch("login", {
              username: username.value,
              password: password.value,
              success() {
                router.push({ name: 'userlist' });
              },
              error() {
                error_message.value = "系统异常，请稍后重试";
              }
            });
          } else {
            error_message.value = resp.result
          }

        }
      })


    }
    return {
      username,
      password,
      confirmedpassword,
      error_message,
      register,
    }
  }
}
</script>

<style scoped>
button {
  width: 100%;
}

.error-message {
  color: red;
}
</style>