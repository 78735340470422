<template>
  <div class="home">
    <ContentBase>首页</ContentBase>
          
        


  </div>
</template>

<script>

import ContentBase from '@/components/ContentBase'

export default {
  name: 'HomeView',
  components: {
    ContentBase,
  }
}
</script>

<style scoped>
</style>

