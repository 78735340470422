<template>
  <div>
    <ContentBase>
      <div class="row">
        <div class="col-3">
          <UserProfileInfo @follow="follow" @unfollow="unfollow" :user="user" />
          <UserProfileWrite v-if="is_me" @post_a_post="post_a_post" />

        </div>
        <div class="col-9">
          <UserProfilePosts :posts="posts" :user="user" @delete_a_post="delete_a_post" />
        </div>
      </div>

    </ContentBase>
  </div>
</template>

<script>
import ContentBase from '@/components/ContentBase'
import UserProfileInfo from '@/components/UserProfileInfo'
import UserProfilePosts from '@/components/UserProfilePosts'
import UserProfileWrite from '@/components/UserProfileWrite'

import { reactive } from 'vue'
import { useRoute } from 'vue-router'
import $ from 'jquery'
import { useStore } from 'vuex'
import { computed } from 'vue'
export default {
  name: 'UserProfileView',
  components: {
    ContentBase,
    UserProfileInfo,
    UserProfilePosts,
    UserProfileWrite
  },
  setup() {
    const store = useStore()
    const route = useRoute()
    const userId = parseInt(route.params.userId)
    const user = reactive({})

    const posts = reactive({})
    const is_me = computed(() => userId === store.state.user.id);

    $.ajax({
      url: "https://app165.acapp.acwing.com.cn/myspace/getinfo/",
      type: "get",
      headers: {
        'Authorization': 'Bearer ' + store.state.user.access,
      },
      data: {
        user_id: userId
      },
      success(resp) {
        user.id = resp.id;
        user.username = resp.username;
        user.photo = resp.photo;
        user.followerCount = resp.followerCount;
        user.is_followed = resp.is_followed;
        console.log(user)
      }
    })

    $.ajax({
      url: "https://app165.acapp.acwing.com.cn/myspace/post/",
      type: "get",
      headers: {
        'Authorization': 'Bearer ' + store.state.user.access,
      },
      data: {
        user_id: userId
      },
      success(resp) {
        console.log(resp)
        console.log(store.state.user.access)
        posts.count = resp.length
        posts.posts = resp
      }
    })

    const post_a_post = (content) => {
      posts.count++,
        posts.posts.unshift({
          id: posts.count,
          userId: 1,
          content: content,
        })
    }
    const delete_a_post = post_id => {
      posts.posts = posts.posts.filter(post => post.id !== post_id)
      posts.count = posts.posts.length
    }

    const follow = () => {
      if (user.is_followed) return;
      user.is_followed = true;
      user.followerCount++;
    }

    const unfollow = () => {
      if (!user.is_followed) return;
      user.is_followed = false;
      user.followerCount--;
    }

    return {
      user,
      posts,
      follow,
      unfollow,
      post_a_post,
      delete_a_post,
      is_me
    }
  }
}
</script>

<style></style>