<template>
    <div>
      <ContentBase>404</ContentBase>
    </div>
  </template>
  
  <script>
  import ContentBase from '@/components/ContentBase'
  
  export default {
    name: 'NotFound',
    components: {
      ContentBase,
    }
  }
  </script>
  
  <style>
  </style>